<template>
  <div class="live-configuration">
    <div class="configuration-content">
      <div class="content-title">
        <div>
          <img src="../../assets/images/live-broadcast/live-icon.png" alt="" />
        </div>
        <h3>{{ label[0] }}</h3>
      </div>
      <div class="from-content">
        <a-form :model="formState" :label-col="labelCol">
          <a-form-item :label="label[1]" class="upload-cover" :required="isType == 1 ? false : true">
            <a-upload-dragger
              class="cover-file"
              :file-list="fileList"
              :remove="removeFile"
              :customRequest="selfUpload"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon" v-show="!formState.coverBase64">
                <plus-outlined></plus-outlined>
              </p>
              <div>
                <img v-show="formState.coverBase64" :src="formState.coverBase64" class="cover" />
              </div>
            </a-upload-dragger>
            <div class="circle" v-if="isType == 1 ? false : true">
              <a-progress type='circle'  :percent="percent"  />
              <span class="circle-point" v-if="sharedDataStore.circlePoint">!网络已断开</span>
            </div>
          </a-form-item>
          <a-form-item :label="label[2]" class="live-room-title" required>
            <a-input v-model:value="formState[title]" />
          </a-form-item>
          <a-form-item v-if="isType == 1 ? false : true" label="视频描述" class="video-description">
            <a-textarea placeholder="请输入视频描述" v-model:value="formState.videoDescribe" :rows="4" />
          </a-form-item>
          <a-form-item v-if="isType == 1 ? true : false" label="直播间密码：" class="live-room-password">
            <a-input v-model:value="formState.password" placeholder="无密码不填写" />
          </a-form-item>
          <a-form-item :label="label[3]" class="live-room-label" required>
            <Label />
          </a-form-item>
          <a-form-item :label="label[4]" class="live-room-resources" v-if="isType == 1 ? true : false">
            <a-tree-select
              show-search
              v-model:value="formState.resourcesId"
              placeholder="输入需要搜索的资源名称或街道等"
              allow-clear
              :tree-data="ResourceSelectionList"
              @select="treeSelect"
              treeNodeFilterProp="title"
              dropdownClassName="live-configuration-treeSelect"
            >
            </a-tree-select>
            <!-- <a-select v-if="isType == 1 ? false : true" v-model:value="formState.videoType" ref="select">
              <a-select-option :value="2">现场回看</a-select-option>
              <a-select-option :value="3">用户上传</a-select-option>
              <a-select-option :value="4">无人机回看</a-select-option>
            </a-select> -->
            <a-button type="primary" class="launch-live">搜索</a-button>
          </a-form-item>
        </a-form>
        <div class="launch-live-box">
          <a-button type="primary" @click="jumpPage" :loading="isSpin">{{ label[5] }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, provide, computed, onMounted, reactive, watch , onBeforeUnmount } from 'vue';
import { hex_md5 } from '@/utils/md_5.js'
import { PlusOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import Label from '@/components/Label.vue';
import { getLabelList } from '@/store/publicFunction.js';
import api from '@/services';
import { message } from 'ant-design-vue';
import { genRandomCapture } from '@/utils/tool';
import '@/utils/fileTool.js'
import io from 'socket.io-client';
import { path } from '@/config';
import { sharedDataStore } from '/src/store/sharedData';
export default {
  components: { 
    PlusOutlined,
    Label,
  },
  setup() {
    // 发起直播 || 发布视频
    const formState = ref({
      // 直播封面
      coverBase64: '',
      // 直播间标题
      roomTitle: null,
      // 密码
      password: null,
      // 标签 || 视频标签
      liveLabelList: null,
      // 使用资源
      resourceType: null,
      resourcesId: null,
      cameraIndexCode: null,

      // 视频文件
      file: null,
      // 视频标签
      videoTitle: null,
      // 视频描述
      videoDescribe: null,
      // 视频类型
      videoType: null,
    });
    const isSpin = ref(false);

    // 获取默认的图片
    const getDefaultPicture = async () => {
      const { data } = await api.getLiveCover();
      if (data.picBase64) formState.value.coverBase64 = `data:image/jpg;base64,${data.picBase64}`;
    };

    // 获取直播间使用资源
    const ResourceSelectionList = ref([]);
    function t(arr) {
      for (var i = 0; i < arr?.length; i++) {
        if (!arr[i].isProduction) {
          arr[i].selectable = false;
        }
        if (arr[i].children) {
          arr[i].children = t(arr[i].children);
        }
        arr[i].resourceName && (arr[i].title = arr[i].resourceName);
        arr[i].resourceType && (arr[i].value = arr[i].resourceType);
        arr[i].resourcesId && (arr[i].value = arr[i].resourcesId);
        delete arr[i].resourceName;
        delete arr[i].resourceType;
        delete arr[i].resourceTypeId;
        delete arr[i].parentType;
        delete arr[i].id;
        delete arr[i].parentId;
        delete arr[i].playUrl;
        // delete arr[i].cameraIndexCode
        delete arr[i].resourcesId;
        // delete arr[i].isProduction
      }
      return arr || [];
    }
    const getResourceSelection = async () => {
      let { data } = await api.getResourceSelection();
      console.log(t(data));
      ResourceSelectionList.value = t(data);
    };
    const treeSelect = (value, node, extra) => {
      formState.value.resourceType = node.vcTreeNode.value;
      formState.value.cameraIndexCode = extra.selectedNodes[0].props.cameraIndexCode;
    };
    // 页面跳转
    // 1 表示直播间  2 表示上传视频
    const router = useRouter();
    const isType = localStorage.getItem('isType');
    let pathName = 'liveBroadcastWatch';
    let functionName = 'getVideoLabelList';
    if (isType == 1) {
      functionName = 'getLiveLabelList';
      getResourceSelection();
    }

    const liveLable = ['发起直播', '直播间封面', '直播间标题', '直播间标签：', '直播使用资源选择：', '发起直播'];
    const videoLable = ['发布视频', '上传视频', '视频标题', '视频标签', '视频类型：', '发布视频'];
    const label = new Array(liveLable.length);
    for (let index = 0; index < label.length; index++) {
      if (isType == 1) {
        label[index] = liveLable[index];
      } else {
        label[index] = videoLable[index];
      }
    }

    const title = computed(() => (isType == 1 ? 'roomTitle' : 'videoTitle'));

    const jumpPage = () => {
      if (!(formState.value.videoTitle || formState.value.roomTitle)) {
        message.error('请输入标题');
        return false;
      }

      if (!formState.value.resourcesId) {
        formState.value.resourceType = undefined;
        formState.value.cameraIndexCode = undefined;
      }
      Object.keys(formState.value).forEach(key => formState.value[key] == null && delete formState.value[key]);

      if (isType == 1) {
        sendLive();
      } else {
        if (!formState.value.file) {
          message.error('请上传文件');
          return false;
        }
        // initSocket()
        check();

        setTimeout( () => {
          if(percent.value <= 1) {
            percent.value = 1
          }
        }, 3000)
      }
    };

    // 获取标签筛选列表
    const labelList = ref(null);
    provide('labelList', labelList);
    const setLabelList = async () => {
      labelList.value = await getLabelList(functionName);
    };
    setLabelList();

    // base64转换
    let fileList = ref([]);
    const selfUpload = ({ file }) => {
      new Promise(resolve => {
        // 1 表示直播封面
        if (isType == 1) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
            formState.value.coverBase64 = fileReader.result;
          };
          // 上传视频
        } else {
          const video = document.createElement('video');
          formState.value.file = file;
          video.src = URL.createObjectURL(file);
          document.body.appendChild(video);
          const videos = document.querySelectorAll('video')[0];
          videos.addEventListener('canplay', function () {
            genRandomCapture(5, videos, src => {
              formState.value.coverBase64 = src;
              video.remove();
            });
          });
        }
      });
    };
    // 修改上传状态 和 限制上传数量
    const handleChange = info => {
      fileList.value = [...info.fileList];
      fileList.value = fileList.value.slice(-1);
      if (fileList.value.length) fileList.value[0].status = 'done';
    };
    // 删除
    const removeFile = () => {
      formState.value.coverBase64 = '';
    };

    // 发起直播
    const sendLive = async () => {
      const labelIdList = ref([]);
      labelList.value.forEach(item => {
        if (item.active) labelIdList.value.push({ id: item.id });
      });
      formState.value.liveLabelList = labelIdList.value;
      if (formState.value.liveLabelList.length <= 0) {
        message.error('请选择直播标签');
        return false;
      }
      try {
        const { data } = await api.sendLiveBroadcast(formState.value);
        console.log(data);
        // 资源直播
        if (formState.value.resourcesId && data?.videoResourcesVO?.pullAddressFlv) {
          router.push({
            name: pathName,
            query: {
              // pullFlow: data?.videoResourcesVO?.pullAddressHls,
              pullFlow: data?.videoResourcesVO?.pullAddressFlv,
              id: data?.id,
              live: true,
              // 1 无人机  2资源  3是自然
              liveType: data?.liveType,
              
              lon: data?.lon,
              lat: data?.lat,
            },
          });
        } else if(data?.pushAddress){
          // 摄像头直播
          router.push({
            name: pathName,
            query: {
              address: data?.pushAddress,
              id: data?.id,
              live: true,
              liveType: 3,
            },
          });
        } else {
          message.errer('服务错误')
        }
      } catch (error) {
        console.error(error);
      }
    };

    // console.log(sharedDataStore)
    // watch(sharedDataStore.circlePoint, (newValue, oldValue) => {
    //   console.log(newValue.circlePoint)
    // })
    watch(
      () => sharedDataStore.circlePoint,
      value => {
        if(!value) {
          check()
        }
      }
    )

  //  const state = reactive({
  //     fileSocket: null
  //   });
    
    // 初始化 socket
    // const initSocket = () => {
    //   state.fileSocket = io(path, {
    //     query: `method=videoheartbeat`,
    //     pingInterval: 2000,
    //     pingTimeout: 15000
    //   });

    //   state.fileSocket.on('connect', data => {
    //     console.log('上传文件socket 连接成功=====', data);
    //   });
    //   // 断开
    //   state.fileSocket.on('disconnect', () => {
    //   })
    //   // 重连
    //   state.fileSocket.on('reconnect', () => {
    //     console.log('重连')
    //     check()
    //   });

    //   state.fileSocket.on('reconnecting', function( nextRetry ){
    //     // 尝试重新连接
    //   });
    // };

    // let i
    let indexValue = 0
    //上传文件的话 得 单独出来
    const test1 = async shardIndex => {
      const labelIdList = ref([]);
      labelList.value.forEach(item => {
        if (item.active) labelIdList.value.push({ id: item.id });
      });
      formState.value.liveLabelList = labelIdList.value;
      //永安里from表单提交
      let params = new FormData();
      Object.keys(formState.value).forEach(key => {
        if (key == 'liveLabelList') {
          formState.value[key].forEach((item, index) => {
            params.append(`videoLabelList[${[index]}].id`, item.id);
          });
        } else {
          params.append(key, formState.value[key]);
        }
      });
      //获取表单中的file
      var file = formState.value.file;
      //文件分片 以1MB去分片
      var shardSize
      if(1024 * 1024 * 100 > file.size) {
        shardSize = 1024 * 1024 * 50
      } else {
        shardSize = 1024 * 1024 * 10
      }
      //定义分片索引
      var shardIndex = shardIndex;
      //定义分片的起始位置
      var start = (shardIndex - 1) * shardSize;
      //定义分片结束的位置 file哪里来的?
      var end = Math.min(file.size, start + shardSize);
      //从文件中截取当前的分片数据
      var fileShard = file.slice(start, end);
      //分片的大小
      var size = file.size;
      //总片数
      var shardTotal = Math.ceil(size / shardSize);
      //文件的后缀名
      var fileName = file.name;
      var suffix = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
      //把视频的信息存储为一个字符串
      var filedetails = file.name + file.size + file.type + file.lastModifiedDate;
      //使用当前文件的信息用md5加密生成一个key 这个加密是根据文件的信息来加密的 如果相同的文件 加的密还是一样的
      var key = hex_md5(filedetails);
      var key10 = parseInt(key, 16);
      //把加密的信息 转为一个64位的
      var key62 = Tool._10to62(key10);
      //前面的参数必须和controller层定义的一样
      params.append('file', fileShard);
      params.append('suffix', suffix);
      params.append('shardIndex', shardIndex);
      params.append('shardSize', shardSize);
      params.append('shardTotal', shardTotal);
      params.append('size', size);
      params.append('key', key62);

      const { data, code, msg } = await api.releaseVideo(params);
      if(code == 500) {
        message.error(msg)
        isSpin.value = false
        return false
      }
      console.log(parseInt(Number((shardIndex / shardTotal).toFixed(2)) * 100), shardIndex , shardTotal)
      if (shardIndex < shardTotal) {
        setProgress(parseInt(Number((shardIndex / shardTotal).toFixed(2)) * 100))
        var index = shardIndex + 1;
        test1(index);
      } else {
        message.info('上传成功')
        router.push('/video-playback');
      }
    };
    const percent = ref(0);
    //判断这个加密文件存在不存在
    const check = async () => {
      var file = formState.value.file;
      //把视频的信息存储为一个字符串
      var filedetails = file.name + file.size + file.type + file.lastModifiedDate;
      //使用当前文件的信息用md5加密生成一个key 这个加密是根据文件的信息来加密的 如果相同的文件 加的密还是一样的
      var key = hex_md5(filedetails);
      var key10 = parseInt(key, 16);
      //把加密的信息 转为一个64位的
      var key62 = Tool._10to62(key10);
      console.log(key62)
      //检查这个key存在不存在
      isSpin.value = true
      const { data, code } = await api.check(key62);
      console.log(!(Boolean(data)))
      if(!(Boolean(data))) {
        //这个方法必须抽离出来
        test1(1);
      } else {
        let index = Number(data.split('-')[0]);
        let total = Number(data.split('-')[1]);
        // console.log('===', index, total);
        indexValue = parseInt(Number((index / total).toFixed(2)) * 100)
        percent.value = parseInt(Number((index / total).toFixed(2)) * 100)
        if (index === total) {
          message.success('极速上传成功');
        } else {
          //找到这个是第几片 去重新上传
          test1(parseInt(index));
        }
      }
    };

    const setProgress = (value) => {
      for (; indexValue < value; indexValue++) {
          (function (indexValue) {
              setTimeout( () => {
                  percent.value += 1
              }, 1000 * indexValue);
          })(indexValue);
      }
    }

    onMounted(() => {
      // getDefaultPicture();
    });
    // onBeforeUnmount(() => {
    //   if (state.fileSocket) {
    //     state.fileSocket.emit('exit', { method: 'live', roomId: roomContent.value.id });
    //     state.fileSocket.disconnect();
    //   }
    // });
    return {
      formState,
      treeSelect,
      fileList,
      isType,
      ResourceSelectionList,
      labelCol: {
        span: 5,
      },
      selfUpload,
      removeFile,
      handleChange,
      jumpPage,
      label,
      title,
      getDefaultPicture,
      isSpin,
      percent,
      sharedDataStore
    };
  },
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.header {
  background-color: #fff;
  width: 1400px;
  margin: 0 auto;
  color: #000;
  ::v-deep(.header-content-left) {
    color: #666666;
    .logo {
      color: #333333;
    }
    .left-active {
      color: #333333;
      &::after {
        background-color: #007fff;
      }
    }
  }
}
.live-configuration {
  // background-color: #f6f6f6;
  min-height: 960px;
  background: url('../../assets/imgs/Initiate_live.png') no-repeat center / 100% 100%;;
  // overflow: hidden;
  .configuration-content {
    // background-color: #fff;
    width: 1400px;
    border-radius: 10px;
    padding-bottom: 80px;
    margin: 30px auto 0;
    .content-title {
      display: flex;
      padding: 30px 20px;
      div {
        img {
          vertical-align: top;
          width: unset;
          height: unset;
        }
        margin-right: 8px;
      }
      h3 {
        color: #333333;
        font-size: 16px;
      }
    }
    .from-content {
      &::before {
        content: '';
        display: block;
        height: 1px;
        background-color: #e5e5e5;
        width: 100%;
      }
      .ant-form {
        color: #666666;
        padding: 30px 0;
        width: 676px;
        margin: 0 auto;
        .ant-row {
          margin-bottom: 15px;
        }
        ::v-deep(.ant-form-item-label > label) {
          color: #666666;
        }
        .upload-cover {
          margin-bottom: 30px;
          .cover-file {
            ::v-deep(.ant-upload) {
              padding: 0;
            }
            .cover {
              height: 145px;
              border-radius: 10px;
            }
          }
          ::v-deep(.ant-form-item-control) {
            width: 263px;
            height: 148px;
            .ant-form-item-children {
              display: block;
              height: 145px;
            }
          }
          ::v-deep(div.ant-upload.ant-upload-drag) {
            border-radius: 10px;
          }
          .ant-upload-drag-icon {
            margin: 0;
            ::v-deep(.anticon-plus) {
              color: #d4d4d4;
              font-size: 24px;
            }
          }
        }
        .live-room-title,
        .live-room-password,
        .video-description {
          ::v-deep(.ant-input),
          ::v-deep(textarea) {
            border-radius: 10px;
            width: 492px;
          }
        }
        .video-description {
          ::v-deep(textarea) {
            resize: none;
            height: 90px;
          }
        }
        .live-room-label {
          ::v-deep(strong) {
            line-height: 23px;
            height: 100%;
          }
        }
        .live-room-resources {
          ::v-deep(.ant-form-item-control-wrapper) {
            display: flex;
            align-items: center;
          }
          ::v-deep(.ant-form-item-children) {
            display: flex;
            .ant-select-selector {
              width: 319px;
              border-radius: 10px 0 0 10px;
              border-right: none;
            }
            button {
              border-radius: 0 10px 10px 0;
              padding: 0 19px;
            }
          }
        }
      }
    }

    .launch-live-box {
      text-align: center;
      button {
        color: #007fff;
        height: 36px;
        width: 160px;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
  .circle {
    position: absolute;
    top: 15px;
    right: -140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .circle-point {
      color: red
    }
  }
}

.tag-list {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 11px 0px rgba(186, 186, 186, 0.5);
}
</style>
