import api from '@/services';

// 标签列表接口
export const getLabelList = async functionName => {
  try {
    const { data } = await api[functionName]();
    return data;
  } catch (error) {
    console.error(error);
  }
};
